<template>
    <div class="updata_box">
        <div style="width: 100%;height: 50px;"></div>
        <div class="updata_box_cenete">
            <div class="updata_div_top">
                <div>
                    <span style="font-size: 22px;color: #FFFFFF;">筛选：</span>
                    <span :class="isactive == 1 ? 'personala' : 'personal'" @click="personal(1)">创意设计组</span>
                    <span :class="isactive == 2 ? 'personala' : 'personal'" @click="personal(2)">文创产品组</span>
                </div>
                <div style="width: 250px">
                    <el-input placeholder="手机号/姓名姓名/邮箱/标题" prefix-icon="el-icon-search" v-model="keyWord" @change="getlist()">
                    </el-input>
                </div>
            </div>
            <div class="updata_div">
                <div class="updata_div_toptext2">作品投票</div>
                <img style="width: 100%;" src="../../../../static/wenchang/9.png" alt="">
                <div class="zuopinglist">
                    <div class="zuopingitem" v-for="(item, index) in fileList2">
                        <div class="zuopingitem_img" @click="gotoxiangq(item)">
                            <el-image style="width: 100%; height: 100%" :src="item.cover" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div
                                style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                {{
                        item.artworkTitle }}</div>
                            <div style="font-size: 16px;color: #2C88CC;margin-left: 15px;">编号:{{ item.id }}</div>
                            <div
                                style="font-size: 16px;color: #2C88CC;margin-left: 15px;margin-right: 15px; display: flex;align-items: center;justify-content: space-between;">
                                <span>票数:{{ item.vote || 0 }}</span>
                                <span style="font-size: 16px;color: #999999;">浏览量: {{ item.browse }}</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/投票.png" alt=""
                                    v-if="item.isVote == 0" @click="gotopiao(item)">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/已投票.png" alt=""
                                    v-else>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="padding-top: 10px; text-align: center;">
                    <el-pagination ref="pagination" background layout="total, prev, pager, next,jumper" prev-text="<"
                        next-text=">" :page-size="currpage" :current-page="page" :total="totalCount"
                        @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 50px;"></div>
        <img class="updata_bottom" src="../../../../static/wenchang/4.png" alt="">
    </div>
</template>

<script>
import { selectPageList, artworkvote } from "../../../util/api"
export default {
    name: 'science_child2',

    data() {
        return {
            value: '',
            page: 1,
            currpage: 9,
            totalCount: 0,
            fileList2: [],
            isactive: 1,
            keyWord: '',
            actualType: '创意设计组',
            userId: localStorage.getItem('useinfo')
        }
    },
    mounted() {

    },
    created() {
        this.getlist()
    },
    methods: {
        gotoxiangq(val) {
            window.open('https://wenchuang-web.123bingo.cn/#/science_child7?id=' + val.id + '&liu=1', '_blank');
        },
        gotopiao(val) {
            this.$confirm('每人只有6次投票机会, 是否投票?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                artworkvote({
                    artworkId: val.id,
                    userId: localStorage.getItem('useinfo'),
                }).then((res) => {
                    if (res.data.message) {
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    } else {
                        this.$message({
                            message: '提交成功！',
                            type: 'success'
                        });
                        this.getlist()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        personal(val) {
            this.isactive = val
            this.page = 1
            if (val == 1) {
                this.actualType = '创意设计组'
            } else if (val == 2) {
                this.actualType = '文创产品组'
            }
            this.getlist()
        },
        getlist() {
            selectPageList({
                page: this.page,
                size: this.currpage,
                keyWord: this.keyWord,
                enroll: 1,
                actualType: this.actualType,
                userId: this.userId,
                soldOut: 0,
            }).then((res) => {
                console.log(res.data);
                const arr = []
                res.data.artworkInfoDao.forEach(item => {
                    if (item.cover) {
                        arr.push(item)
                    }
                });
                this.fileList2 = arr;
                this.totalCount = res.data.total;
            })
        },
        handleSizeChange(cpage) {
            this.currpage = cpage;
            this.getlist();
        },
        //分页按钮 首页和最后一页
        handleCurrentChange(cpage) {
            if (this.keyWord) {
                this.page = 1;
            } else {
                this.page = cpage;
            }
            this.getlist();
        },
    }
}
</script>
<style scoped>
.submits {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
}

.submit {
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
    cursor: pointer;
}

.updata_bottom {
    /* position: fixed;
    bottom: 0 */
    width: 100%;
    margin-bottom: -6px;
}

.updata_box_cenete {
    margin: 61px auto;
    width: 1311px;
    height: 1800px;
    background: #FFFFFF;
    border-radius: 30px;
}

.updata_box {
    background-image: url("../../../../static/wenchang/8.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.updata_div {
    position: relative;
    padding: 0 71px;
    padding-top: 42px;
    width: 100%;
    height: 100%;
}

.updata_div_toptext {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.updata_div_toptext2 {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.input1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input2 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input3 {
    width: 100%;
    display: flex;
}

.select1 {
    display: flex;
    align-items: center;
}

.select2 {}

.input3uplodtxt {
    font-weight: 100;
    font-size: 14px;
    color: #F34242;
    margin-top: 20px;
    margin-left: 152px;
}

.el-upload__tip {
    font-size: 14px;
    color: #F34242;
}

.personal {
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
    opacity: 0.5;
    margin: 0 20px;
}

.personala {
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
    margin: 0 20px;
}

.personal_box {
    position: absolute;
    top: 7%;
    left: 7%;
}

@media (max-width: 480px) {}

@media (max-width: 5000px) {
    p {
        font-size: 14px !important;
    }

    .updata_div_top {
        width: 100%;
        height: 96px;
        background: #43A1CC;
        border-radius: 30px 30px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .zuopinglist {
        margin-top: 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .zuopinglist .zuopingitem:last-child:nth-child(3n-2) {
        margin-right: calc(60% + (10% / 4) * 2);
    }

    .zuopinglist .zuopingitem:last-child:nth-child(3n-1) {
        margin-right: calc(30% + (10% / 4) * 1);
    }

    .zuopingitem {
        cursor: pointer;
        text-align: center;
        width: 330px;
        height: 439px;
        background-image: url('../../../../static/wenchang/575.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 10px 0;
    }

    .zuopingitem_img {
        padding: 11px;
        padding-bottom: 0px;
        width: 100%;
        height: 190px;
    }

    .zuopingitem_txt {
        margin: 0px 11px;
        background: #FFFFFF;
        border-radius: 0px 0px 16px 16px;
        border: 1px solid #EBCA92;
        text-align: left;
        padding-bottom: 7px;
    }

    .zuopingitem_txt div {
        padding: 8px 0;
    }

    .toupiao {
        width: 133px;
        height: 42px;
        margin: 29px auto;
        padding: 0 !important;
    }
}
</style>